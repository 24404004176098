import React from 'react';
import Layout from '../Components/Layout/Layout';
import StandardTradingConditions from './StandardTradingConditions/StandardTradingConditions';

const standardTradingConditions = () => {
  return (
    <Layout>
      <StandardTradingConditions />
    </Layout>
  );
};

export default standardTradingConditions;
